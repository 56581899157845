// CI colors:
// Yellow: #ffff00
// Black: #000000
// Grey: #DADADA
// Brown: #766A62 (for text links)

// Color scheme

// Needs to be kept in sync with app_primary_color in config/config.yml
$color-primary: #ffff00;
$color-primary-300: #ffff66;
$color-primary-200: #ffff99;
$color-primary-100: lighten($color-primary, 38%); // #ffa8d7

$color-primary-on-dark-background: $color-primary;
$color-primary-on-light-background: $color-black;

$color-action: $color-black;
$color-highlight: #C7C2BA;

$color-secondary: #dadada;

$color-brown: #766a62;

/** Ionic CSS Variables **/
:root {
    // override ionic variables
    --ion-tab-bar-color-selected: #{$color-brown};

    ion-button {
        &.button-clear:not(.ion-color-dark) {
            --ion-color-primary: var(--wm-color-link);
        }
    }
}
