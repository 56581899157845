@use "colors" as *;

// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import "swiper/scss";
@import "swiper/scss/pagination";
@import '@ionic/angular/css/ionic-swiper';

@import 'assets/css/fonts.css';

html.wm-plt-mobile .hidden-mobile {
    display: none;
}

html:not(.wm-plt-mobile) .visible-mobile {
    display: none;
}

html.wm-plt-desktop .hidden-desktop {
    display: none;
}

html:not(.wm-plt-desktop) .visible-desktop {
    display: none;
}

.wm-plt-desktop ion-toast {
    font-size: 16px;
    width: 400px;
    left: 16px;
    bottom: 16px;
    right: auto;
    top: auto;
}

.wm-plt-desktop ion-modal.auto-height {
    &.bottom {
        align-items: flex-end;
    }

    --height: auto;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 80vh;
            overflow: auto;
        }
    }
}

ion-popover.auto-width {
    --width: auto;
}

// Be more specific than Ionic's selectors
.wm-plt-desktop ion-loading,
.wm-plt-mobile ion-loading {
    --background: var(--ion-color-dark);
    --spinner-color: var(--ion-color-dark-contrast);
    color: var(--ion-color-dark-contrast);
}

.wm-plt-desktop ion-loading {
    --max-width: 400px;
    font-size: 16px;
}

.refresher-refreshing-icon ion-spinner {
    display: block;
    margin: 0 auto;
}

p {
    margin: 0 0 16px 0;
}

.answer > p, .answer > li, p, li {
    font-size: 16px;
    color: var(--ion-text-color);
}

// Webkit
.alert-radio-group::-webkit-scrollbar {
    width: 8px;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-thumb {
    background-color: var(--ion-color-primary);
    border-radius: 4px;
    border: 1px solid white;
}

ion-chip.ion-color-success {
    background: var(--ion-color-success-shade, #0ec254) !important;
    color: white;
}

ion-header ion-button.button-with-badge {
    font-size: 16px;
    height: 40px;
    --overflow: visible;

    ion-badge {
        position: absolute;
        top: -10px;
        right: -10px;
        border-radius: 10px;
        min-width: 20px;
        min-height: 20px;
        letter-spacing: -0.1px;
    }
}

ion-popover.notification-popover {
    --width: 400px;
}

a {
    color: var(--wm-color-link);

    &.read-more {
        text-decoration: none;
        font-size: 14px;
        line-height: 16px;
        display: block;
        margin-top: 16px;
        margin-bottom: 48px;
    }
}

.item-has-focus {
    ion-label[position="floating"] {
        color: var(--wm-color-floating-label) !important;
    }
}

ion-alert {
    --ion-color-primary: var(--wm-color-alert-modal-primary);
}

.item-has-focus {
    ion-label[position="floating"] {
        color: var(--wm-color-floating-label) !important;
    }
}

ion-chip {
    &.ion-color-primary {
        background: var(--wm-color-chip-primary-background-rgba);
        color: var(--wm-color-chip-primary-color);
    }
}

ion-avatar {
    box-shadow: inset 0 0 0 1px $color-avatar-border;
    border-radius: 100%;
}

ion-item.item-input::part(native),
ion-item.item-select::part(native) {
    caret-color: auto;
    border-bottom-color: var(--border-color);
}

.swiper-slide {
    flex-direction: column;
}
