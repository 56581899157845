@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'),
    local('Roboto-Light'),
    url('../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'),
    local('Roboto-Regular'),
    url('../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'),
    local('Roboto-Medium'),
    url('../fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    src: local('Roboto Bold'),
    local('Roboto-Bold'),
    url('../fonts/Roboto-Bold.ttf') format('truetype');
}
