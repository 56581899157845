@function shade($color, $percentage: 12%) {
    @return mix(black, $color, $percentage);
}

@function tint($color, $percentage: 10%) {
    @return mix(white, $color, $percentage);
}

@function contrast($color) {
    @if (red($color) * 0.299 + green($color) * 0.587 + blue($color) * 0.114) > 186 {
        @return #000000;
    } @else {
        @return #ffffff;
    }
}

@function hexToRGB($hexColor) {
    @return #{red($hexColor)}, #{green($hexColor)}, #{blue($hexColor)};
}

@mixin createIonicPalette($name, $value) {
    --ion-color-#{$name}: #{$value};
    --ion-color-#{$name}-rgb: #{hexToRGB($value)};
    --ion-color-#{$name}-shade: #{shade($value)};
    --ion-color-#{$name}-tint: #{tint($value)};
    --ion-color-#{$name}-contrast: #{contrast($value)};
    --ion-color-#{$name}-contrast-rgb: #{hexToRGB(contrast($value))};
}
