@use "mixins/colors" as *;

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
$theme-colors: (
        'primary': $color-primary,
        'primary-on-dark-background': $color-primary-on-dark-background,
        'primary-on-light-background': $color-primary-on-light-background,
        'secondary': $color-secondary,
        'tertiary': #009ee3,
        'success': $color-green-400,
        'warning': #ffce00,
        'danger': #f04141,
        'dark': #222428,
        'medium': #989aa2,
        'light': #f4f5f8,
);

:root {
    @each $name, $value in $theme-colors {
        @include createIonicPalette($name, $value);
    }
}
