:root {
    --color-white: #{$color-white};
    --ion-text-color: var(--ion-color-dark-tint);
    --ion-item-border-color: #dfdfdf;
    --ion-color-step-600: #666;

    --wm-color-action: #{$color-action};
    --wm-color-highlight: #{$color-highlight};
    --wm-color-text-subtle: #{$color-text-subtle};

    .swiper {
        --bullet-background: var(--wm-color-text-subtle);
        --bullet-background-active: var(--wm-color-action);
    }

    // custom css variables depending on second tier app variables
    --wm-color-static-background: #{$color-static-background};
    --wm-color-buttons: #{$color-buttons};
    --wm-color-buttons-dark-background: #{$color-buttons-dark-background};
    --wm-color-floating-label: #{$color-floating-label};
    --wm-color-link: #{$color-link};
    --wm-color-alert-modal-primary: #{$color-alert-modal-primary};
    --wm-color-headline: #{$color-headline};
    --wm-color-chip-primary-background-rgba: #{$color-chip-primary-background-rgba};
    --wm-color-chip-primary-color: #{$color-chip-primary-color};
}
